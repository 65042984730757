import React, { useState, useEffect } from 'react';
import Card from '@leafygreen-ui/card';
import ExpandableCard from '@leafygreen-ui/expandable-card';
import { css } from '@leafygreen-ui/emotion';
import { H3 } from '@leafygreen-ui/typography';
import Badge from '@leafygreen-ui/badge';
import Button from '@leafygreen-ui/button';
import { Link } from 'react-router-dom';
import { baseUrl } from '../config';

const cardStyle = css`
	margin: 1em;
`;
const badgeColors = ['lightgray', 'darkgray', 'red', 'blue', 'green', 'yellow'];
const getBadgeColor = (tag) => {
	if (tag) {
		let tagId =
			tag
				.split('')
				.map((char) => char.charCodeAt(0))
				.reduce((s, a) => s + a, 0) % 6;
		return badgeColors[tagId];
	}
};

export default function TeamResultSummary(props) {
	const { department, results, numberOfTeamMembers, reviewCount } = props;

	const {
		quality,
		efficiency,
		collaboration,
		communication,
		initiative,
		independence,
		clientFocus,
		technicalSkills,
		rating,
		overall,
	} = results;

	const getColumnClass = (number) => {
		let numberPercent = number * 100;
		return numberPercent.toFixed() > 40 ? (numberPercent.toFixed() > 70 ? 'green' : 'orange') : 'red';
	};

	const getColumnValue = (number) => {
		let numberPercent = number * 100;
		return `${numberPercent.toFixed()}%`;
	};

	return (
		<Card className={cardStyle}>
			<H3 className="dev-name">
				<div className="name-wrap">
					<div className="name">
						<React.Fragment>
							{department} <Badge variant={getBadgeColor(department)}>{department}</Badge>
							<span style={{ display: 'block', marginTop: '10px' }}>
								({reviewCount} reviews | {numberOfTeamMembers} team members)
							</span>
						</React.Fragment>
					</div>
				</div>
			</H3>
			<div className="scores-wrap">
				<div className="review-group">
					<div className="score-row">
						<div className={`score-col ${getColumnClass(quality)}`}>
							<p>Quality of work</p>
							<span>{getColumnValue(quality)}</span>
						</div>

						<div className={`score-col ${getColumnClass(efficiency)}`}>
							<p>Efficiency</p>
							<span>{getColumnValue(efficiency)}</span>
						</div>

						<div className={`score-col ${getColumnClass(collaboration)}`}>
							<p>Collaboration</p>
							<span>{getColumnValue(collaboration)}</span>
						</div>

						<div className={`score-col ${getColumnClass(communication)}`}>
							<p>Communication</p>
							<span>{getColumnValue(communication)}</span>
						</div>

						<div className={`score-col ${getColumnClass(initiative)}`}>
							<p>Initiative</p>
							<span>{getColumnValue(initiative)}</span>
						</div>

						<div className={`score-col ${getColumnClass(independence)}`}>
							<p>Independence</p>
							<span>{getColumnValue(independence)}</span>
						</div>

						<div className={`score-col ${getColumnClass(clientFocus)}`}>
							<p>Client Focus</p>
							<span>{getColumnValue(clientFocus)}</span>
						</div>

						<div className={`score-col ${getColumnClass(technicalSkills)}`}>
							<p>Technical Skills</p>
							<span>{getColumnValue(technicalSkills)}</span>
						</div>

						<div className="score-col">
							<p>Rating</p>
							<span>{rating.toFixed(2)}</span>
						</div>

						<div className={`score-col overall ${getColumnClass(overall)}`}>
							<p>Overall Score</p>
							<span>{getColumnValue(overall)}</span>
						</div>
					</div>
				</div>
			</div>
		</Card>
	);
}
