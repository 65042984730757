import React, { useState, useEffect } from 'react';
import ExpandableCard from '@leafygreen-ui/expandable-card';
import { H2, H3, Body } from '@leafygreen-ui/typography';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import TextInput from '@leafygreen-ui/text-input';
import TextArea from '@leafygreen-ui/text-area';
import Icon from '@leafygreen-ui/icon';
import Button from '@leafygreen-ui/button';
import { useParams, useNavigate } from 'react-router-dom';
import { baseUrl } from '../config';
import ResultSummary from '../components/ResultSummary';

export default function App() {
	let params = useParams();
	let [post, setPost] = useState({});
	let [showModal, setShowModal] = useState(false);
	let [author, setAuthor] = useState('');
	let [body, setBody] = useState('');
	const navigate = useNavigate();

	let [isLoading, setIsLoading] = useState(true);

	const handleNewComment = async () => {
		setIsLoading(true);
		await fetch(`${baseUrl}/posts/comment/${params.id}`, {
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify({
				author,
				body,
			}),
		});

		let result = await fetch(`${baseUrl}/posts/${params.id}`).then((resp) => resp.json());

		setPost(result);

		setAuthor('');
		setBody('');
		setShowModal(false);

		setIsLoading(false);
	};

	useEffect(() => {
		loadPost();
	}, [params]);

	const loadPost = async () => {
		try {
			let results = await fetch(`${baseUrl}/posts/${params.id}`).then((resp) => resp.json());

			setPost(results);
			setIsLoading(false);

			console.log(results);
		} catch (error) {
			console.error('Error loading posts:', error.message);
		}
	};

	if (isLoading) {
		return <p>Loading...</p>;
	}

	return (
		<React.Fragment>
			<div style={{ display: 'flex', padding: '24px 0', margin: '1em', justifyContent: 'flex-end' }}>
				<Button variant="primary" leftGlyph={<Icon glyph="Megaphone" />} onClick={() => setShowModal(true)}>
					Add Review Comment
				</Button>
			</div>

			<ResultSummary
				{...post}
				onDelete={() => loadPost()} // Pass the loadPosts function as a prop
				isSingle
				isLoading={isLoading}
			/>

			{post && post.comments && (
				<div style={{ padding: '24px 0', margin: '1em', justifyContent: 'flex-end' }}>
					<ExpandableCard title="Review Comments">
						{post.comments.map((comment, index) => {
							return (
								<React.Fragment key={index}>
									<Body weight="medium">{comment.author}: </Body>
									<Body>{comment.body}</Body>
								</React.Fragment>
							);
						})}
					</ExpandableCard>
				</div>
			)}
			<ConfirmationModal
				title="Add a comment"
				open={showModal}
				buttonText="Save Comment"
				onConfirm={handleNewComment}
				onCancel={() => setShowModal(false)}
			>
				<TextInput
					label="Name"
					description="Enter your name"
					onChange={(e) => setAuthor(e.target.value)}
					value={author}
				/>
				<TextArea label="Comment" onChange={(e) => setBody(e.target.value)} rows="5" value={body} />
			</ConfirmationModal>
		</React.Fragment>
	);
}
