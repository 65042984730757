import React, { useState, useEffect } from 'react';
import Card from '@leafygreen-ui/card';
import ExpandableCard from '@leafygreen-ui/expandable-card';
import { css } from '@leafygreen-ui/emotion';
import { H3 } from '@leafygreen-ui/typography';
import Badge from '@leafygreen-ui/badge';
import Button from '@leafygreen-ui/button';
import { Link } from 'react-router-dom';
import { baseUrl } from '../config';

const cardStyle = css`
	margin: 1em;
`;
const badgeColors = ['lightgray', 'darkgray', 'red', 'blue', 'green', 'yellow'];
const getBadgeColor = (tag) => {
	let tagId =
		tag
			.split('')
			.map((char) => char.charCodeAt(0))
			.reduce((s, a) => s + a, 0) % 6;
	return badgeColors[tagId];
};

const groupBy = (arr, key) => {
	return arr.reduce((acc, obj) => {
		const groupKey = obj[key];
		if (!acc[groupKey]) {
			acc[groupKey] = { data: [], sum: 0, count: 0, average: 0 };
		}

		acc[groupKey].data.push(obj);

		// Check if 'Other' group or if answer is non-numeric
		if (groupKey === 'other') {
			return acc;
		}

		acc[groupKey].sum += parseInt(obj.answer);
		acc[groupKey].count += 1;
		acc[groupKey].average = acc[groupKey].sum / acc[groupKey].count;
		return acc;
	}, {});
};

const calculateSingleResult = (results) => {
	const reviewCount = (results.quality && results.quality.count) || 4;

	const qualityOfWorkAverage =
		(((results.quality && results.quality.average) >= 0 ? results.quality.average : 0) || 0).toFixed(2) * 100;

	const efficiencyAverage =
		(((results.efficiency && results.efficiency.average) >= 0 ? results.efficiency.average : 0) || 0).toFixed(2) * 100;

	const collaborationAverage =
		(((results.collaboration && results.collaboration.average) >= 0 ? results.collaboration.average : 0) || 0).toFixed(
			2
		) * 100;

	const communicationAverage =
		(((results.communication && results.communication.average) >= 0 ? results.communication.average : 0) || 0).toFixed(
			2
		) * 100;

	const initiativeAverage =
		(((results.initiative && results.initiative.average) >= 0 ? results.initiative.average : 0) || 0).toFixed(2) * 100;

	const independenceAverage =
		(((results.independence && results.independence.average) >= 0 ? results.independence.average : 0) || 0).toFixed(2) *
		100;

	const clientFocusAverage =
		(((results.clientFocus && results.clientFocus.average) >= 0 ? results.clientFocus.average : 0) || 0).toFixed(2) *
		100;

	const ratingAverage = ((results.rating && results.rating.average) || 0).toFixed(2);

	// Calculate overall score
	const overallScore =
		(qualityOfWorkAverage || 0) +
		(efficiencyAverage || 0) +
		(collaborationAverage || 0) +
		(communicationAverage || 0) +
		(initiativeAverage || 0) +
		(independenceAverage || 0) +
		(clientFocusAverage || 0);

	const numberOfScores = 7;
	const overallScoreAverage = overallScore / numberOfScores;

	return {
		quality: qualityOfWorkAverage,
		efficiency: efficiencyAverage,
		collaboration: collaborationAverage,
		communication: communicationAverage,
		initiative: initiativeAverage,
		independence: independenceAverage,
		clientFocus: clientFocusAverage,
		rating: ratingAverage,
		overall: overallScoreAverage,
		reviewCount: reviewCount,
	};
};

const getOtherData = (combinedData) => {
	let combinedOtherData = [];

	combinedData.map((reviewGroup) => {
		const { results } = reviewGroup;
		let otherData = (results.other && results.other.data) || [];
		let grouppedOtherData = groupBy(otherData, 'question');

		return combinedOtherData.push(grouppedOtherData);
	});

	let qData = [];

	combinedOtherData.map((questionData, ind) => {
		Object.keys(questionData).map((question) => {
			let question_label = question;
			if (question === 'question-29') {
				question_label = "1. What are the developer's strongest skills or attributes?";
			}
			if (question === 'question-30') {
				question_label = '2. In which areas does the developer need improvement?';
			}
			if (question === 'question-31') {
				question_label = '3. Can you provide an example of a situation where the developer exceeded expectations?';
			}
			if (question === 'question-32') {
				question_label =
					'4. Can you provide an example of a situation where the developer faced challenges or did not meet expectations?';
			}

			const answers = combinedOtherData[ind][question].data;

			answers.map((answerItem, idx) => {
				return qData.push({
					label: question,
					answer: answerItem.answer ? answerItem.answer : 'N/A',
				});
			});
		});
	});

	let grouppedOtherData = groupBy(qData, 'label');

	return grouppedOtherData;
};

const getTotalReviewCount = (combinedData) => {
	let totalCount = 0;

	combinedData.map((reviewGroup) => {
		const { results } = reviewGroup;
		const reviewCount = (results.quality && results.quality.count) || 4;

		totalCount = totalCount + reviewCount;
	});

	const reviewCount = totalCount / 4;

	return reviewCount;
};

export default function ResultSummary(props) {
	const { _id, developerName, combinedData, department, onDelete, isSingle = false } = props;

	const [grouppedOtherData, setOtherData] = useState([]);
	const [totalReviewCount, setTotalReviewCount] = useState(0);
	const [overalResult, setOveralResult] = useState({
		quality: 0,
		efficiency: 0,
		collaboration: 0,
		communication: 0,
		initiative: 0,
		independence: 0,
		clientFocus: 0,
		technicalSkills: 0,
		rating: 0,
		overall: 0,
	});

	useEffect(() => {
		const otherData = getOtherData(combinedData);
		const reviewCount = getTotalReviewCount(combinedData);

		setOtherData(otherData);

		const overallResult = calculateOveralScore(combinedData);

		setOveralResult(overallResult);

		setTotalReviewCount(reviewCount);
	}, [combinedData]); // Trigger effect when combinedData changes

	const calculateOveralScore = (combinedData) => {
		let quality = 0;
		let efficiency = 0;
		let collaboration = 0;
		let communication = 0;
		let initiative = 0;
		let independence = 0;
		let clientFocus = 0;
		let technicalSkills = 0;
		let rating = 0;
		let overall = 0;

		const dataLength = combinedData.length;
		let peerReviewCount = 0;
		let dspmReviewCount = 0;

		combinedData.map((reviewGroup, ind) => {
			const { reviewType, answers, results, date, _id } = reviewGroup;

			const resultData = calculateSingleResult(results);

			quality += resultData.quality;
			efficiency += resultData.efficiency;
			collaboration += resultData.collaboration;

			communication += resultData.communication;
			initiative += resultData.initiative;
			independence += resultData.independence;
			if (reviewType == 'DS/PM Review') {
				clientFocus += resultData.clientFocus;
				dspmReviewCount += 1;
			} else {
				technicalSkills += resultData.clientFocus;
				peerReviewCount += 1;
			}

			rating += parseFloat(resultData.rating);
			// overall += resultData.overall;
		});

		overall =
			(quality / dataLength +
				efficiency / dataLength +
				collaboration / dataLength +
				communication / dataLength +
				initiative / dataLength +
				independence / dataLength +
				clientFocus / dspmReviewCount +
				technicalSkills / peerReviewCount) /
			8;

		let overallResult = {
			quality: quality / dataLength,
			efficiency: efficiency / dataLength,
			collaboration: collaboration / dataLength,
			communication: communication / dataLength,
			initiative: initiative / dataLength,
			independence: independence / dataLength,
			clientFocus: clientFocus / dspmReviewCount,
			technicalSkills: technicalSkills / peerReviewCount,
			rating: (rating / dataLength).toFixed(2),
			overall: overall,
		};

		return overallResult;
	};

	// // Divide by the number of scores (8 in this case)
	// const reviewCount = (results.quality && results.quality.count) || 4;

	const handleDelete = async (id) => {
		// Show a confirmation dialog
		const isConfirmed = window.confirm('Are you sure you want to delete this data?');

		// If the user confirms, proceed with deletion
		if (isConfirmed) {
			try {
				const response = await fetch(`${baseUrl}/posts/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const responseData = await response.json();
				console.log('Delete Success:', responseData);

				// Call the onDelete function passed from the parent
				onDelete();
			} catch (error) {
				console.error('Bulk Delete Error:', error.message);
			}
		}
	};

	function isNumeric(value) {
		return !isNaN(parseFloat(value)) && isFinite(value);
	}

	const getColumnClass = (number) => {
		if (isNumeric(number)) {
			let numberPercent = number;
			return numberPercent.toFixed() > 40 ? (numberPercent.toFixed() > 70 ? 'green' : 'orange') : 'red';
		}
		return '';
	};

	const getColumnValue = (number) => {
		if (isNumeric(number)) {
			let numberPercent = number;
			return `${numberPercent.toFixed()}%`;
		} else {
			return number;
		}
	};

	return (
		<Card className={cardStyle}>
			<H3 className="dev-name">
				<div className="name-wrap">
					<div className="avatar">
						<Link to={`/post/${_id}`}>
							<img src="https://via.placeholder.com/96" alt="placeholder" />
						</Link>
					</div>
					<div className="name">
						{isSingle ? (
							<React.Fragment>
								{developerName} <span>({totalReviewCount} reviews)</span>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Link to={`/post/${combinedData[0]?._id}`}>{developerName}</Link>{' '}
								<span>({totalReviewCount} reviews)</span>
							</React.Fragment>
						)}

						<div className="department">
							<React.Fragment>
								<Badge variant={getBadgeColor(department)}>{department}</Badge>{' '}
							</React.Fragment>
						</div>
					</div>
				</div>
			</H3>
			<div className="scores-wrap">
				{combinedData.map((reviewGroup, ind) => {
					const { reviewType, answers, results, date, _id } = reviewGroup;

					const resultData = calculateSingleResult(results);

					return (
						<div className="review-group" key={ind}>
							<div className="group-header">
								<p>
									Type: <span>{reviewType}</span> <span>({resultData.reviewCount / 4} reviews)</span>
								</p>

								<div className="actions">
									<Button variant="danger" onClick={() => handleDelete(_id)}>
										Delete Data
									</Button>
								</div>
							</div>

							<div className="score-row regular-row">
								<div className={`score-col ${getColumnClass(resultData.quality)}`}>
									<p>Quality of work</p>
									<span>{getColumnValue(resultData.quality)}</span>
								</div>

								<div className={`score-col ${getColumnClass(resultData.efficiency)}`}>
									<p>Efficiency</p>
									<span>{getColumnValue(resultData.efficiency)}</span>
								</div>

								<div className={`score-col ${getColumnClass(resultData.collaboration)}`}>
									<p>Collaboration</p>
									<span>{getColumnValue(resultData.collaboration)}</span>
								</div>

								<div className={`score-col ${getColumnClass(resultData.communication)}`}>
									<p>Communication</p>
									<span>{getColumnValue(resultData.communication)}</span>
								</div>

								<div className={`score-col ${getColumnClass(resultData.initiative)}`}>
									<p>Initiative</p>
									<span>{getColumnValue(resultData.initiative)}</span>
								</div>

								<div className={`score-col ${getColumnClass(resultData.independence)}`}>
									<p>Independence</p>
									<span>{getColumnValue(resultData.independence)}</span>
								</div>

								<div
									className={`score-col ${reviewType == 'Peer Review' ? '' : getColumnClass(resultData.clientFocus)}`}
								>
									<p>Client Focus</p>
									<span>{reviewType == 'Peer Review' ? 'N/A' : `${getColumnValue(resultData.clientFocus)}`}</span>
								</div>

								<div
									className={`score-col ${reviewType == 'Peer Review' ? getColumnClass(resultData.clientFocus) : ''}`}
								>
									<p>Technical Skills</p>
									<span>{reviewType == 'Peer Review' ? `${getColumnValue(resultData.clientFocus)}` : 'N/A'}</span>
								</div>

								<div className="score-col">
									<p>Rating</p>
									<span>{resultData.rating}</span>
								</div>

								<div className={`score-col row-overall ${getColumnClass(resultData.overall)}`}>
									<p>Overall Score</p>
									<span>{getColumnValue(resultData.overall)}</span>
								</div>
							</div>
						</div>
					);
				})}

				<div className="review-group">
					<div className="group-header">
						<p>
							Type: <span>Overall Result</span> <span>({totalReviewCount} reviews)</span>
						</p>
					</div>

					<div className="score-row">
						<div
							className={`score-col overall ${
								overalResult.quality.toFixed() > 40 ? (overalResult.quality.toFixed() > 70 ? 'green' : 'orange') : 'red'
							}`}
						>
							<p>Quality of work</p>
							<span>{overalResult.quality.toFixed()}%</span>
						</div>

						<div
							className={`score-col overall ${
								overalResult.efficiency.toFixed() > 40
									? overalResult.efficiency.toFixed() > 70
										? 'green'
										: 'orange'
									: 'red'
							}`}
						>
							<p>Efficiency</p>
							<span>{overalResult.efficiency.toFixed()}%</span>
						</div>

						<div
							className={`score-col overall ${
								overalResult.collaboration.toFixed() > 40
									? overalResult.collaboration.toFixed() > 70
										? 'green'
										: 'orange'
									: 'red'
							}`}
						>
							<p>Collaboration</p>
							<span>{overalResult.collaboration.toFixed()}%</span>
						</div>

						<div
							className={`score-col overall ${
								overalResult.communication.toFixed() > 40
									? overalResult.communication.toFixed() > 70
										? 'green'
										: 'orange'
									: 'red'
							}`}
						>
							<p>Communication</p>
							<span>{overalResult.communication.toFixed()}%</span>
						</div>

						<div
							className={`score-col overall ${
								overalResult.initiative.toFixed() > 40
									? overalResult.initiative.toFixed() > 70
										? 'green'
										: 'orange'
									: 'red'
							}`}
						>
							<p>Initiative</p>
							<span>{overalResult.initiative.toFixed()}%</span>
						</div>

						<div
							className={`score-col overall ${
								overalResult.independence.toFixed() > 40
									? overalResult.independence.toFixed() > 70
										? 'green'
										: 'orange'
									: 'red'
							}`}
						>
							<p>Independence</p>
							<span>{overalResult.independence.toFixed()}%</span>
						</div>

						<div
							className={`score-col overall ${
								overalResult.clientFocus.toFixed() > 40
									? overalResult.clientFocus.toFixed() > 70
										? 'green'
										: 'orange'
									: 'red'
							}`}
						>
							<p>Client Focus</p>
							<span>{overalResult.clientFocus.toFixed()}%</span>
						</div>

						<div
							className={`score-col overall ${
								overalResult.technicalSkills.toFixed() > 40
									? overalResult.technicalSkills.toFixed() > 70
										? 'green'
										: 'orange'
									: 'red'
							}`}
						>
							<p>Technical Skills</p>
							<span>{overalResult.technicalSkills.toFixed()}%</span>
						</div>

						<div className="score-col">
							<p>Rating</p>
							<span>{overalResult.rating}</span>
						</div>

						<div
							className={`score-col overall ${
								overalResult.overall.toFixed() > 40 ? (overalResult.overall.toFixed() > 70 ? 'green' : 'orange') : 'red'
							}`}
						>
							<p>Overall Score</p>
							<span>{overalResult.overall.toFixed() + '%'}</span>
						</div>
					</div>
				</div>

				<div className="other">
					<ExpandableCard title="Additional Comments" description="View additional comments" defaultOpen={isSingle}>
						<div className="comments">
							{Object.keys(grouppedOtherData).map((question) => {
								let question_label = question;
								if (question === 'question-29') {
									question_label = "1. What are the developer's strongest skills or attributes?";
								}
								if (question === 'question-30') {
									question_label = '2. In which areas does the developer need improvement?';
								}
								if (question === 'question-31') {
									question_label =
										'3. Can you provide an example of a situation where the developer exceeded expectations?';
								}
								if (question === 'question-32') {
									question_label =
										'4. Can you provide an example of a situation where the developer faced challenges or did not meet expectations?';
								}

								return (
									<div key={question}>
										<p>{question_label}</p>
										{grouppedOtherData[question].data && (
											<ul>
												{grouppedOtherData[question].data.map((answerItem, idx) => {
													return <li key={idx}>{answerItem.answer ? answerItem.answer : 'N/A'}</li>;
												})}
											</ul>
										)}
									</div>
								);
							})}
						</div>
					</ExpandableCard>
				</div>
			</div>
		</Card>
	);
}
