import React, { useState, useEffect } from 'react';
import { H2 } from '@leafygreen-ui/typography';
import ResultSummary from '../components/ResultSummary';
import { baseUrl } from '../config';
import Badge from '@leafygreen-ui/badge';

const badgeColors = ['lightgray', 'darkgray', 'red', 'blue', 'green', 'yellow'];
const getBadgeColor = (tag) => {
	if (tag) {
		let tagId =
			tag
				.split('')
				.map((char) => char.charCodeAt(0))
				.reduce((s, a) => s + a, 0) % 6;
		return badgeColors[tagId];
	}
};

export default function App() {
	let [posts, setPosts] = useState([]);
	const [selectedTag, setSelectedTag] = useState(null);

	const handleTagClick = async (tag) => {
		try {
			const results = await fetch(`${baseUrl}/posts`).then((resp) => resp.json());

			const filteredData = tag ? results.filter((item) => item.department === tag) : results;

			setPosts(filteredData);
			setSelectedTag(tag);
		} catch (error) {
			console.error('Error loading posts:', error.message);
		}
	};

	useEffect(() => {
		loadPosts();
	}, []);

	const loadPosts = async () => {
		try {
			const results = await fetch(`${baseUrl}/posts`).then((resp) => resp.json());

			setPosts(results);
		} catch (error) {
			console.error('Error loading posts:', error.message);
		}
	};

	const departments = ['Front-End', 'Magento', 'Shopify', 'WP Back-End', 'WP Full-Stack'];

	return (
		<React.Fragment>
			<div style={{ marginTop: 40 }}>
				<div style={{ display: 'flex', marginBottom: 40, justifyContent: 'space-between', alignItems: 'center' }}>
					<H2>Results</H2>
					<div style={{ display: 'flex', gap: '10px' }}>
						{departments.map((tag) => (
							<Badge
								darkMode={selectedTag == tag}
								key={tag}
								onClick={() => handleTagClick(tag)}
								variant={getBadgeColor(tag)}
							>
								{tag}
							</Badge>
						))}
						<Badge onClick={() => handleTagClick(null)} variant={getBadgeColor(null)}>
							Clear Filter
						</Badge>
					</div>
				</div>

				<div>
					{posts.map((result, index) => (
						<ResultSummary
							key={index}
							{...result}
							onDelete={() => loadPosts()} // Pass the loadPosts function as a prop
						/>
					))}
					{posts.length === 0 && <p>Loading...</p>}
				</div>
			</div>
		</React.Fragment>
	);
}
