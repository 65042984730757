import './styles.scss';

import LeafygreenProvider from '@leafygreen-ui/leafygreen-provider';
import Layout from './components/Layout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ToastProvider } from '@leafygreen-ui/toast';

import Home from './pages/Home';
import Post from './pages/Post';
import Upload from './pages/Upload';
import TeamPerformance from './pages/TeamPerformance';

function App() {
	return (
		<LeafygreenProvider>
			<ToastProvider>
				<Router>
					<Routes>
						<Route path="/" element={<Layout />}>
							<Route path="/" element={<Home />} />
							<Route path="/upload" element={<Upload />} />
							<Route path="/post/:id" element={<Post />} />
							<Route path="/team-performance" element={<TeamPerformance />} />
						</Route>
					</Routes>
				</Router>
			</ToastProvider>
		</LeafygreenProvider>
	);
}

export default App;
