import React, { useState } from 'react';
import { H2 } from '@leafygreen-ui/typography';
import TextInput from '@leafygreen-ui/text-input';
import { Select, Option } from '@leafygreen-ui/select';
import FormFooter from '@leafygreen-ui/form-footer';
import { Toast } from '@leafygreen-ui/toast';
import { css } from '@leafygreen-ui/emotion';
import { baseUrl } from '../config';
import CSVReader from 'react-csv-reader';
import Button from '@leafygreen-ui/button';

const formStyle = css`
	height: 100vh;
	min-width: 767px;
	margin: 10px;

	.form-group {
		margin-bottom: 20px;
	}
`;

export default function App() {
	const [jsonData, setJsonData] = useState(null);
	let [developerName, setDeveloperName] = useState('');
	let [department, setDepartment] = useState('');
	let [reviewType, setReviewType] = useState('DS/PM Review');
	let [toastOpen, setToastOpen] = useState(false);
	let [hasDevName, setHasName] = useState(false);
	let [dataUploaded, setDataUploaded] = useState(false);

	// Initialize useHistory

	const handleSubmit = async () => {
		setHasName(true);
	};

	const sanitizeKeys = (key, index) => {
		// let sanitizedKey = key;
		let sanitizedKey = 'question-' + (index - 5);
		if (key === 'ID') {
			sanitizedKey = 'entryID';
		} else if (key === 'Name') {
			sanitizedKey = 'name';
		} else if (key === 'Email') {
			sanitizedKey = 'email';
		} else if (key === 'Last modified time') {
			sanitizedKey = 'last_mod_date';
		} else if (key === 'Completion time') {
			sanitizedKey = 'completed_time';
		} else if (key === 'Start time') {
			sanitizedKey = 'start_time';
		}

		return sanitizedKey;
	};

	const handleFile = (data, fileInfo) => {
		// Assuming the CSV has a header row
		const headers = data[0];

		// Trim whitespace from each header
		const trimmedHeaders = headers.map((header) => header.trim());

		// Remove the header row and create an array of objects
		const jsonData = data.slice(1).map((row) => {
			const rowData = {};
			trimmedHeaders.forEach((header, index) => {
				let sanitizedHeader = sanitizeKeys(header, index);
				// Check if the column should be excluded

				let rowValue = row[index];
				let rowScore = 0;

				if (rowValue === 'Agree' || rowValue === 'Disagree' || rowValue === "I don't know") {
					if (rowValue === 'Agree') {
						rowScore = 1;
					} else if (rowValue === 'Disagree') {
						rowScore = -1;
					}
				} else {
					rowScore = rowValue.trim();
				}

				rowData[sanitizedHeader] = rowScore;
			});

			return rowData;
		});

		// Set the JSON data in the state
		setJsonData(jsonData);
	};

	const handleUpload = async () => {
		await fetch(`${baseUrl}/posts`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify({
				developerName: developerName,
				department: department,
				reviewType: reviewType,
				jsonData,
			}),
		}).then((resp) => resp.json());

		setDeveloperName('');
		setToastOpen(true);
		setDataUploaded(true);
		setTimeout(() => setToastOpen(false), 3000);
	};

	return (
		<React.Fragment>
			<div style={{ marginTop: 40 }}>
				<H2 style={{ marginBottom: 40 }}>Upload Results</H2>
				<div>
					{!hasDevName && (
						<form className={formStyle}>
							<div className="form-group">
								<TextInput
									label="Developer Name"
									description="Enter name"
									onChange={(e) => setDeveloperName(e.target.value)}
									value={developerName}
								/>
							</div>
							<div className="form-group">
								<Select
									label="Department"
									description="Select department"
									value={department}
									onChange={(value) => setDepartment(value)}
								>
									<Option>Front-End</Option>
									<Option>WP Back-End</Option>
									<Option>WP Full-Stack</Option>
									<Option>Magento</Option>
									<Option>Shopify</Option>
								</Select>
							</div>
							<div className="form-group">
								<Select
									label="Review Type"
									description="Select Review Type"
									value={reviewType}
									onChange={(value) => setReviewType(value)}
								>
									<Option>DS/PM Review</Option>
									<Option>Peer Review</Option>
								</Select>
							</div>
							<div className="form-group">
								<CSVReader onFileLoaded={handleFile} />
							</div>

							<FormFooter
								primaryButton={{
									text: 'Next',
									onClick: handleSubmit,
									disabled: developerName ? false : true,
								}}
							/>
						</form>
					)}

					{hasDevName && (
						<React.Fragment>
							{jsonData && (
								<React.Fragment>
									{!dataUploaded && (
										<Button onClick={handleUpload} variant="primary">
											Upload to API
										</Button>
									)}

									<div>
										<h2>Converted JSON Data</h2>
										{/* <pre>{JSON.stringify(jsonData, null, 2)}</pre> */}
									</div>
								</React.Fragment>
							)}
						</React.Fragment>
					)}
				</div>
			</div>

			<Toast
				variant="success"
				title="Results added"
				description="The results entry has been stored."
				open={toastOpen}
				close={() => setToastOpen(false)}
			/>
		</React.Fragment>
	);
}
