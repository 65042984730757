import React, { useState, useEffect } from 'react';
import { H2 } from '@leafygreen-ui/typography';
import TeamResultSummary from '../components/TeamResultSummary';
import { baseUrl } from '../config';

export default function App() {
	let [posts, setPosts] = useState([]);

	useEffect(() => {
		loadPosts();
	}, []);

	const loadPosts = async () => {
		try {
			let results = await fetch(`${baseUrl}/posts/team`).then((resp) => resp.json());

			setPosts(results);
		} catch (error) {
			console.error('Error loading posts:', error.message);
		}
	};

	return (
		<React.Fragment>
			<div style={{ marginTop: 40 }}>
				<div style={{ display: 'flex', marginBottom: 40, justifyContent: 'space-between', alignItems: 'center' }}>
					<H2>Team Performance</H2>
				</div>

				<div>
					{posts.map((result, index) => (
						<TeamResultSummary key={index} {...result} />
					))}
					{posts.length === 0 && <p>Loading...</p>}
				</div>
			</div>
		</React.Fragment>
	);
}
