import { SideNav, SideNavItem } from '@leafygreen-ui/side-nav';
import { Link, useLocation } from 'react-router-dom';

export default function Navigation({ className }) {
	const location = useLocation();

	return (
		<SideNav aria-label="Navigation Bar" className={className} style={{ position: 'sticky' }}>
			<SideNavItem aria-label="Results" as={Link} active={location.pathname === '/'} to="/">
				Results
			</SideNavItem>

			<SideNavItem
				aria-label="Upload Results"
				as={Link}
				active={location.pathname === '/team-performance'}
				to="/team-performance"
			>
				Team Performance
			</SideNavItem>

			<SideNavItem aria-label="Upload Results" as={Link} active={location.pathname === '/upload'} to="/upload">
				Upload Results
			</SideNavItem>
		</SideNav>
	);
}
